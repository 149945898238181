import React, { useState, useEffect } from 'react';
import './App.css';
import axios from 'axios';
import io from 'socket.io-client';

function App() {
  const [database, setDatabase] = useState('');
  const [table, setTable] = useState('');
  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');
  const [tableInfo, setTableInfo] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [temporaryUrl, setTemporaryUrl] = useState(''); // Estado para a URL temporária
  const [webhookKeys, setWebhookKeys] = useState([]);
  const [mapeamentos, setMapeamentos] = useState({});
  const [generatedScript, setGeneratedScript] = useState("");


  const handleSelectChange = (coluna, valor) => {
    setMapeamentos(prevState => ({
      ...prevState,
      [coluna]: valor
    }));
  };

  const handleMappingSubmit = async () => {
    try {
      // Aqui você pode enviar os mapeamentos de volta para o servidor
      const credenciais = {
        mapeamentos,
        table,
        database,
        user,
        password
      }

      const response = await axios.post('https://wferraz.painelgeral.link/gerar-script', credenciais);
      setGeneratedScript(response.data.script);
      setCurrentStep(3);

    } catch (error) {
      // Tratar erro, como exibir mensagem de erro
    }
  };



  useEffect(() => {
    const socket = io('https://wferraz.painelgeral.link/');

    socket.on('connect', () => {
    });

    socket.on('webhookData', (data) => {
      const processedKeys = data.map(key => key.replace('leads.0.', ''));
      setWebhookKeys(processedKeys);
      setCurrentStep(2); // Muda para a etapa de mapear campos
    });

    return () => socket.disconnect();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post('https://vetor.painelgeral.link/processar-dados', {
        database,
        table,
        user,
        password
      });

      setTemporaryUrl(response.data.temporaryUrl); // Armazenar a URL temporária
      setTableInfo(response.data.tableInfo);
      setCurrentStep(1); // Muda para a etapa de exibir URL temporária
    } catch (error) {
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <div className='header'>
          <img className='logo' src='/vetorwferraz.png' alt='logo WFerraz & Vetor' width="150"></img>
          <div className='menu'>
            <h2 className='modulo selecionado'>RD Station</h2>
            <a className='modulo' href='/#'>Meta Ads</a>
          </div>
          <div className='usuario'>
            <p>Olá, Vitor!</p>
          </div>
        </div>
        <div className='main'>
          {currentStep === 0 && (
            <form onSubmit={handleSubmit}>
              <div>
                <label>
                  Banco de Dados:
                  <input type="text" value={database} onChange={e => setDatabase(e.target.value)} />
                </label>
              </div>
              <div>
                <label>
                  Tabela:
                  <input type="text" value={table} onChange={e => setTable(e.target.value)} />
                </label>
              </div>
              <div>
                <label>
                  Usuário:
                  <input type="text" value={user} onChange={e => setUser(e.target.value)} />
                </label>
              </div>
              <div>
                <label>
                  Senha:
                  <input type="password" value={password} onChange={e => setPassword(e.target.value)} />
                </label>
              </div>
              <div>
                <button type="submit">Enviar</button>
              </div>
            </form>
          )}

          {currentStep === 1 && (
            <div>
              <p>URL para Webhook do RD Station:</p>
              <input type="text" value={temporaryUrl} readOnly />
            </div>
          )}

          {currentStep === 2 && (
            <div>
              <h2>Mapeamento de Campos</h2>
              {tableInfo.map((column, index) => (
                <div key={index}>
                  <label>{column.Field}: </label>
                  <select onChange={e => handleSelectChange(column.Field, e.target.value)}>
                    <option value="">Selecione uma opção</option>
                    {webhookKeys.map((key, idx) => (
                      <option key={idx} value={key}>{key}</option>
                    ))}
                  </select>
                </div>
              ))}
              <button onClick={handleMappingSubmit}>Confirmar Mapeamento</button>
            </div>
          )}

          {currentStep === 3 && (
            <div>
              <h2>Seu Script Gerado</h2>
              <textarea
                value={generatedScript}
                readOnly
                rows={10}
                style={{ width: '100%' }}
              ></textarea>
              <button onClick={() => navigator.clipboard.writeText(generatedScript)}>
                Copiar Script
              </button>
            </div>
          )}
        </div>
      </header>
    </div>
  );
}

export default App;